import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout/Layout"
import Article from "../../../components/Article/Article"
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"
import i18n from "i18next"

const SeoArticle = ({ data }) => {
  useEffect(() => {
    i18n.changeLanguage("pl")
  }, [])
  return (
    <Layout
      seo={{
        title: "Inline CSS. Czym jest, do czego służy i czy warto go używać?",
        description:
          "Dowiedz się, czym jest CSS inline, w jaki sposób działa i kiedy warto go używać, a także sprawdź, czy inline CSS ma jakiś wpływ na działania związane z SEO.",
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/baza-wiedzy/artykuly/",
                name: "Baza wiedzy",
              },
            },
            {
              "@type": "ListItem",
              position: 3,
              item: {
                "@id": "/baza-wiedzy/artykul/co-to-jest-inline-css/",
                name: "Inline CSS. Czym jest, do czego służy i czy warto go używać?",
              },
            },
          ],
        },
      }}
    >
      <Breadcrumbs
        path={[
          { url: "/", text: "Główna" },
          {
            url: "/baza-wiedzy/artykuly/",
            text: "Baza wiedzy / Artykuły",
          },
          {
            url: "/baza-wiedzy/artykul/co-to-jest-inline-css",
            text: "Co to jest inline CSS?",
          },
        ]}
      />
      <Article img={<img src={data.file.childImageSharp.fluid.src} alt={"Co to jest inline CSS?"} />}>
        <h1>Co to jest CSS?</h1>
        <p>
          <strong>
            CSS (ang. <em>Cascading Style Sheets</em>) służy do formatowania układu strony internetowej, czyli
            kontrolowania takich elementów jak: czcionka, kolor i rozmiar tekstu. Ponadto umożliwia zmianę koloru tła,
            rozporządzanie rozmieszczeniem elementów na stronie, jak również przystosowanie witryny do urządzeń
            mobilnych.
          </strong>
        </p>
        <h2>Czym jest CSS inline?</h2>
        <p>
          To metoda stosowania styli bezpośrednio w kodzie HTML elementu na stronie internetowej. W przeciwieństwie do
          zewnętrznych arkuszy stylów, które są umieszczane w osobnym pliku i dołączane do strony za pomocą linku,
          inline CSS pozwala na definiowanie stylów wewnątrz samego tagu HTML. Dzięki temu przeglądarka nie musi
          wczytywać dodatkowych plików, co może skrócić czas ładowania strony.
        </p>
        <h2>Gdzie umieszcza się style inline CSS?</h2>
        <p>Umieszcza się je zazwyczaj w 3 miejscach:</p>
        <ul>
          <li>
            <p>w oddzielnym pliku z rozszerzeniem .css,</p>
          </li>
          <li>
            <p>w tagu &lt;style&gt; wewnątrz tagu &lt;head&gt;,</p>
          </li>
          <li>
            <p>wewnątrz tagu HTML, przy użyciu atrybutu „style”.</p>
          </li>
        </ul>
        <p>
          W ostatnim z przypadków mamy do czynienia ze „stylem inline”, który tworzony jest podobnie, jak sam CSS. Lecz
          z kilkoma różnicami. Np. style inline wpływa bezpośrednio na znacznik, w którym jest zapisany.
        </p>
        <h2>Do czego służy CSS inline?</h2>
        <p>
          Służy do zastosowania unikalnego stylu dla pojedynczego elementu HTML (np. ustawienie koloru dla nagłówka H1
          czy akapitu &lt;p&gt;). Jednak w odróżnieniu od zwykłego CSS, CSS inline nie wpływa na wszystkie elementy tego
          typu na stronie, lecz wyłącznie na jeden konkretny.
        </p>
        <h2>Co tak naprawdę oznacza kaskadowość?</h2>
        <p>
          Kaskadowość to jeden z kluczowych aspektów CSS, który pozwala na kontrolowanie hierarchii stylów i ich
          dziedziczenia przez elementy HTML.
        </p>
        <p>
          Kaskadowość <strong>oznacza, że style mogą być definiowane na różnych poziomach</strong> – od globalnych do
          lokalnych – a następnie łączone w jedną całość. Dzięki temu można tworzyć bardziej spójne i elastyczne
          projekty stron. Jednak stosowanie inline CSS może zakłócić ten proces, ponieważ style definiowane są
          bezpośrednio dla poszczególnych elementów, co utrudnia ich zarządzanie i modyfikowanie.
        </p>
        <h2>Kiedy stosowanie stylów inline jest konieczne?</h2>
        <p>
          Specjaliści raczej nie używają CSS inline. Jednak istnieją okoliczności, kiedy jest to konieczne. Na przykład
          w przypadku:
        </p>
        <ul>
          <li>
            <p>poczty elektronicznej HTML,</p>
          </li>
          <li>
            <p>starych stron internetowych,</p>
          </li>
          <li>
            <p>gdy jest to konieczne ze względu na technologię wykonania strony.</p>
          </li>
        </ul>
        <p>
          Jednak są to wyjątki, gdyż – jak wspominano – w większości przypadków CSS inline jest raczej odradzany podczas
          tworzenia witryn.
        </p>
        <h2>Zalety stosowania</h2>
        <p>
          Jednym z głównych powodów stosowania inline CSS jest <strong>przyspieszenie ładowania strony</strong>.
          Ponieważ style są zawarte bezpośrednio w kodzie HTML, przeglądarka nie musi wczytywać dodatkowych plików, co
          przekłada się na szybsze wyświetlanie zawartości. To szczególnie ważne dla użytkowników korzystających z
          urządzeń mobilnych, gdzie prędkość połączenia może być ograniczona.
        </p>
        <h2>Czy CSS inline wpływa na SEO?</h2>
        <p>
          Optymalizacja SEO to nie tylko odpowiednie słowa kluczowe, ale także{" "}
          <strong>szybkość ładowania strony</strong>. Właśnie tutaj inline CSS może okazać się przydatny.
        </p>
        <p>
          Przeglądarki i roboty wyszukiwarek doceniają dobrą <strong>szybkość ładowania strony</strong>, co może wpłynąć
          na lepsze pozycjonowanie witryny w wynikach wyszukiwania. Ponadto stosowanie inline CSS ułatwia indeksowanie
          strony przez roboty wyszukiwarek.
        </p>
        <h2>Jak poprawnie stosować CSS?</h2>
        <p>Aby skorzystać z zalet, ważne jest umiejętne zastosowanie tej metody. Oto kilka wskazówek.</p>
        <ul>
          <li>
            <p>
              Należy <strong>unikać nadużywania</strong> inline CSS i stosować je tylko tam, gdzie jest to konieczne.
            </p>
          </li>
          <li>
            <p>
              Warto <strong>pamiętać o utrzymaniu czytelności</strong> kodu HTML poprzez stosowanie odpowiednich wcięć i
              separacji elementów.
            </p>
          </li>
          <li>
            <p>
              Dbanie o <strong>optymalizację kodu CSS</strong>, na przykład poprzez minimalizowanie ilości deklaracji i
              skracanie wartości, może przyczynić się do jeszcze większego przyspieszenia ładowania strony.
            </p>
          </li>
        </ul>
        <h2>Alternatywy</h2>
        <p>
          Warto rozważyć także inne techniki, takie jak kompresja plików CSS, stosowanie serwera proxy do buforowania
          zawartości czy wykorzystanie Content Delivery Network (CDN) do szybszego dostarczania plików.{" "}
          <strong>Każde z tych rozwiązań może przyczynić się do poprawy wydajności strony </strong>i lepszej
          optymalizacji SEO.
        </p>
        <h2>CSS dla firm – podsumowanie</h2>
        <p>
          CSS to rozwiązanie dla właścicieli firm, którzy chcą przyspieszyć ładowanie swojej strony internetowej i
          poprawić jej pozycjonowanie w wyszukiwarkach. Warto jednak pamiętać o umiejętnym stosowaniu tej metody i
          dbaniu o optymalizację kodu CSS, aby w pełni skorzystać z jej zalet. Warto pomyśleć także o nowszych
          alternatywach.
        </p>
        <p>
          <br className="ProseMirror-trailingBreak" />
        </p>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "img/full/inline-css.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
  }
`

export default SeoArticle
